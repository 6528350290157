import React from "react"
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider, Page, Card, FooterHelp, Link, Frame, TextField } from '@shopify/polaris';

export default function ThemeShow(props){
  return (
    <AppProvider features={{newDesignLanguage: true}} i18n={enTranslations}>
      <Frame>
        <Page
          title={props.shop.name}
          breadcrumbs={[{content: 'Flight deck', url: '/flightdeck/' + props.shop.id}]}
        >
          <Card
            title={props.theme.name} 
            primaryFooterAction={{content: 'Download config.yml', url: props.theme.id + '/config.yml'}}>
            <Card.Section>
              <p>Created at: {props.theme.created_at}</p>
              <p>Updated at: {props.theme.updated_at}</p>
            </Card.Section>
            <Card.Section>
              <TextField
                label="config.yml"
                value={props.theme_yml}
                multiline={4}
                disabled
                autoComplete="off"
              />
            </Card.Section>
            <Card.Section>
            <TextField
                label=".env"
                value={props.theme_env}
                multiline={14}
                disabled
                autoComplete="off"
              />
            </Card.Section>
          </Card>
          <FooterHelp>
            Learn more about{' '}
            <Link url="https://github.com/bravetheskies/mothership#mothership" external={true}>
              Mothership
            </Link>
          </FooterHelp>
        </Page>
      </Frame>
    </AppProvider>
  );
}
