import React from "react"
import PropTypes from "prop-types"
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider, Page, Card, Heading, FooterHelp, Link, Frame } from '@shopify/polaris';
import ThemeList from './ThemeList';

export default function ShopShow(props){
  return (
    <AppProvider features={{newDesignLanguage: true}} i18n={enTranslations}>
      <Frame>
        <Page
          title={props.shop.name}
          breadcrumbs={[{content: 'Flight deck', url: '/flightdeck'}]}
        >
          <Card>
            <ThemeList { ...props } />
          </Card>
          <FooterHelp>
            Learn more about{' '}
            <Link url="https://github.com/bravetheskies/mothership#mothership" external={true}>
              Mothership
            </Link>
          </FooterHelp>
        </Page>
      </Frame>
    </AppProvider>
  );
}
