import React, {useCallback, useState} from "react"
import PropTypes from "prop-types"
import enTranslations from '@shopify/polaris/locales/en.json';
import {SearchMinor} from '@shopify/polaris-icons';
import {
  AppProvider,
  Page,
  Card,
  ResourceList,
  ResourceItem,
  Avatar,
  TextStyle,
  Button,
  FooterHelp,
  Link,
  TextField,
  Icon,
  Filters
} from '@shopify/polaris';

export default function ShopList(props){
  const [searchInputValue, setSearchInputValue] = useState('');
  const [shops, setShops] = useState(props.shops);

  const renderShop = (shopItem) => {
    const {id, url, shopify_domain, name} = shopItem;
    const media = <Avatar shop size="medium" name={shopify_domain} />;
    return (
      <ResourceItem
        id={id}
        url={`/flightdeck/${id}`}
        media={media}
        accessibilityLabel={`View details for ${shopify_domain}`}
      >
        <h3>
          <TextStyle variation="strong">{name}</TextStyle>
        </h3>
        <div>{shopify_domain}</div>
      </ResourceItem>
    );
  }
  const filters = [

  ]

  const updateQueryText = useCallback(
    (value) => {
      const original_shops = [...props.shops]
      setSearchInputValue(value);

      if (value === '') {
        setShops(original_shops);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = original_shops.filter((shop) =>
        shop.name.match(filterRegex),
      );
      setShops(resultOptions);
    },
    [shops],
  );


  const filterControl = (
    <Filters
      queryValue={searchInputValue}
      onQueryChange={updateQueryText}
      onQueryClear={() => updateQueryText("")}
      queryPlaceholder="Search stores"
      filters={filters}
    >
    </Filters>
  )
  return (
    <AppProvider features={{newDesignLanguage: true}} i18n={enTranslations}>
      <Page
        title="Flight deck"
        primaryAction={<Button primary url="/shopify/login" external>Add shop</Button>}
      >
        <Card title={`As of Feburary 2023 Mothersip has been deprecated.`} sectioned>
          <p>To get credentails for Theme Kit themes use the <a href="https://apps.shopify.com/theme-access">Shopify Theme Access app</a>. For more details view the <a href="https://github.com/bravetheskies/mothership">Mothership README on GitHub</a>.</p>
        </Card>
        <Card title={`Welcome to the flight deck, ${props.user.given_name}`} sectioned>
          <p>Your email: {props.user.email}.</p>
        </Card>
        <Card>
          <ResourceList
            showHeader
            resourceName={{singular: 'shop', plural: 'shops'}}
            items={shops}
            renderItem={renderShop}
            filterControl={filterControl}
          />
        </Card>
        <FooterHelp>
          Learn more about{' '}
          <Link url="https://github.com/bravetheskies/mothership#mothership" external={true}>Mothership</Link>
        </FooterHelp>
      </Page>
    </AppProvider>
  );
}
