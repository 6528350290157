import React, { useState, useCallback, useEffect } from "react"
import { ResourceList, ResourceItem, TextStyle, Toast, Filters, Button, Badge, Stack, ProgressBar } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import moment from 'moment'
import axios from 'axios';
import setRequestHeaders from "../helpers/setRequestHeaders";
import BackupModal from "./BackupModal";

export default function ThemeList(props){
  const [themes, setThemes] = useState(props.themes);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [queryValue, setQueryValue] = useState(null);
  const [jobStatus, setJobStatus] = useState(props.job_status);
  const [modalOpen, setModalOpen] = useState(false);
  const toastMarkup = toastMessage != "" ? (
    <Toast error={toastMessage.includes("Error")} content={toastMessage} onDismiss={() => setToastMessage("")} />
  ) : null;

  const bulkActions = [
    {
      content: 'Delete Themes',
      destructive: true,
      icon: DeleteMinor,
      onAction: () => deleteThemes(selectedThemes)
    },
  ];

  const deleteThemes = (themeIDs) => {
    console.log(themeIDs)
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content;
    axios.post(`/flightdeck/${props.shop.id}/theme_destroy`, {
      theme_ids: themeIDs,
    })
    .then(function (response) {
      setToastMessage(response.data.message)
      const destroyed_themes = response.data.destroyed_themes
      const newThemes = props.themes.filter(theme => !destroyed_themes.includes(theme.id));
      setThemes(newThemes)
      setSelectedThemes([])
    })
    .catch(function (error, two) {
      setToastMessage("Error: Something went wrong")
      setSelectedThemes([])
    })
  }

  const renderTheme = (theme) => {
    const {id, name, role, updated_at} = theme;
    const roleMarkup = (role == 'main') ? (<span> - <TextStyle variation="negative">PUBLISHED</TextStyle></span>) : null
    return (
      <ResourceItem
        id={id}
        accessibilityLabel={`View details for ${name}`}
        shortcutActions={[{content: 'Download config.yml', url: props.shop.id + '/theme/' + id + '/config.yml'}]}
        url={props.shop.id + '/theme/' + id}
      >
        <TextStyle variation="strong">{name} {roleMarkup}</TextStyle>
        <Stack alignment="center">
          <p>
            {moment(updated_at).fromNow()}
          </p>
          {renderJobStatus(theme)}
        </Stack>
      </ResourceItem>
    );
  }

  const renderJobStatus = (theme) => {
    if (!jobStatus) return null;
    const { options, status, message, num, total } = jobStatus;
    const { theme_id, new_theme_id } = options ? options : {};
    let { id } = theme;
    id = id.toString();

    if (id === theme_id && status === 'queued') {
      return <Badge status={'warning'} progress={"incomplete"}>Backup Queued</Badge>
    } else if (new_theme_id && id === new_theme_id.toString()) {
      switch (status) {
        case 'queued':
          return <Badge status={'warning'} progress={"incomplete"}>Queued</Badge>
        case 'working':
          return <Stack alignment="center">
            <Badge status={'attention'} progress={"partiallyComplete"}>Backing up</Badge>
            <div style={{minWidth:'200px'}}>
              <ProgressBar progress={((num/total)*100).toFixed(2)} size="small" />
            </div>
            <TextStyle variation="subdued">({num}/{total})</TextStyle>
          </Stack>
        case 'completed':
          const completed_at = new Date(message.split(' at ')[1]);
          const minsFromNow = moment().diff(completed_at, 'minutes');
          if (minsFromNow < 60) return <Badge status={'success'} progress={"complete"}>Backup Complete</Badge>;
          break;
        default:
          return null
      }
    }
  }

  const checkJobStatus = () => {
    const { status, uuid } = jobStatus || {};

    if (status && status !== 'completed') {
      console.log('checking job status')
      setRequestHeaders();
      axios.get(`/flightdeck/${props.shop.id}/backup_status/${uuid}`)
          .then(response => {
            setJobStatus(response.data.status)
          })
          .catch(error => {
            setToastMessage(`Error updating backup status: ${JSON.stringify(error)}`)
          })
    }
  }

  const backupTheme = (title, downloadConfig) => {
    closeModal();
    const publishedTheme = themes.find(t => t.role === 'main');
    setRequestHeaders();
    setJobStatus({ working: true });
    axios.post(`/flightdeck/${props.shop.id}/theme_backup/${publishedTheme.id}`, {
      theme_name: title,
    })
        .then(function (response) {
          setToastMessage(response.data.message)
          setSelectedThemes([]);
          setThemes([ response.data.theme, ...themes ])
          setJobStatus(response.data.job_status);
          if (downloadConfig) window.open(props.shop.id + '/theme/' + response.data.theme.id + '/config.yml', '_blank');
        })
        .catch(function (error, two) {
          setToastMessage("Error: Something went wrong")
          console.log(error)
          setSelectedThemes([]);
          setJobStatus(null);
        })
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const handleQueryValueChange = useCallback((value) => setQueryValue(value), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleClearAll = useCallback(() => handleQueryValueRemove(), [handleQueryValueRemove]);

  useEffect(() => {
    checkJobStatus();
  }, [ jobStatus ])

  const filterControl = (
      <Filters
          queryValue={queryValue}
          filters={[]}
          appliedFilters={[]}
          onQueryChange={handleQueryValueChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleClearAll}
      >
        <div style={{paddingLeft: '8px'}}>
          <Button onClick={() => setModalOpen(true)} loading={jobStatus && jobStatus.status !== 'completed'}>
            Create Backup
          </Button>
        </div>
      </Filters>
  );

  return (
    <React.Fragment>
      <ResourceList
        showHeader
        resourceName={{singular: 'theme', plural: 'themes'}}
        items={queryValue ? themes.filter(theme => theme.name.toLowerCase().indexOf(queryValue) > -1).sort((a,b) => a.role === 'main' ? -1 : 1 ) : themes.sort((a,b) => a.role === 'main' ? -1 : 1 )}
        renderItem={renderTheme}
        bulkActions={bulkActions}
        selectedItems={selectedThemes}
        onSelectionChange={setSelectedThemes}
        selectable
        filterControl={filterControl}
      />
      {toastMarkup}
      <BackupModal open={modalOpen} backupTheme={backupTheme} closeModal={closeModal} />
    </React.Fragment>
  );
}
