import React from "react"
import PropTypes from "prop-types"
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider, Page, Card, Link } from '@shopify/polaris';

class ShopifyPage extends React.Component {
  render () {
    return (
      <AppProvider features={{newDesignLanguage: true}} i18n={enTranslations}>
        <Page
          narrowWidth
          title="Brave the Skies"
        >
          <Card
            title="What is this?"
            sectioned
            primaryFooterAction={{content: 'Make contact', url: "https://bravetheskies.com/pages/contact", external: true }}
          >
            <p>This app allows <Link url="https://bravetheskies.com" external={true}>Brave the Skies</Link> to keep your theme secure and up to date.</p>
          </Card>
        </Page>
      </AppProvider>
    );
  }
}

export default ShopifyPage
