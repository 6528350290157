import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, TextField, Checkbox, Stack, TextStyle } from '@shopify/polaris';

const defaultTitle = `[BACKUP] Master - ${new Date().toLocaleDateString()}`;

export default ({ open, closeModal, backupTheme }) => {
    const [ active, setActive ] = useState(open);
    const [ title, setTitle ] = useState(defaultTitle);
    const [ downloadConfig, setDownloadConfig ] = useState(false);
    const [ error, setError ] = useState(null);
    const node = useRef(null);

    const handleChange = val => {
        const testVal = val.replace(/ /g, '');
        setTitle(val);

        if (testVal.length === 0) {
            setError('Title cannot be blank');
        } else {
            setError(null);
        }
    }

    const handleFocus = useCallback(() => {
        if (node.current == null) {
            return;
        }
        node.current.input.select();
        document.execCommand('copy');
    }, []);

    const handleClose = () => {
        setTitle(defaultTitle);
        closeModal();
    }

    useEffect(() => {
        setActive(open);
    }, [ open ]);

    return <Modal
        open={active}
        onClose={handleClose}
        title="Create a backup"
        primaryAction={{
            content: 'Create Backup',
            disabled: !!error,
            onAction: () => {
                backupTheme(title, downloadConfig);
            },
        }}
        secondaryActions={[
            {
                content: 'Cancel',
                destructive: true,
                onAction: handleClose,
            },
        ]}
    >
        <Modal.Section>
            <Stack vertical>
                <Stack.Item>
                    <TextField
                        ref={node}
                        label="Backup Title"
                        onFocus={handleFocus}
                        value={title}
                        onChange={handleChange}
                    />
                </Stack.Item>
                {error && <Stack.Item>
                    <TextStyle variation="negative">{error}</TextStyle>
                </Stack.Item>}
                <Stack.Item>
                    <Checkbox
                        label="Download config.yml for backup?"
                        checked={downloadConfig}
                        onChange={() => setDownloadConfig(!downloadConfig)}
                    />
                </Stack.Item>
            </Stack>
        </Modal.Section>
    </Modal>
}
